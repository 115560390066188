import React, { useRef, useState, useEffect } from 'react';
import {
  Row,
  Col,
  Container,
  Button,
  Form,
  Alert,
  Image,
  InputGroup,
} from 'react-bootstrap';
import useAuth from '../hooks/useAuth';
import axios from '../api/axios';
import { useNavigate, useLocation } from 'react-router-dom';
import logo from '../assets/images/logo.png';
import Loading from './Loading/Loading';
import { deviceId } from '../context/firestore';

const SPA_SERVER_URL = process.env.REACT_APP_SPA_SERVER_URL;

function SignIn() {
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from || '/';

  const userRef = useRef();
  const errRef = useRef();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  // User States
  const [user, setUser] = useState('');
  const [pwd, setPwd] = useState('');
  const [errMsg, setErrMsg] = useState('');

  useEffect(() => {
    userRef.current.focus();
  }, []);
  useEffect(() => {
    setErrMsg('');
  }, [user, pwd]);

  const getStaff = async (token) => {
    var URL = SPA_SERVER_URL + 'api/spa/getstaffdetails';
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        deviceid: deviceId,
        Authorization: 'Bearer ' + token,
      },
    };
    const response = await fetch(URL, options);
    const responseJson = await response.json();
    localStorage.setItem('staff', JSON.stringify(responseJson));
    // Response Improve for all PAI and storing in localstorage
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const params = new URLSearchParams();
    params.append('username', user);
    params.append('password', pwd);
    params.append('grant_type', 'password');
    params.append('type', 'cashier');
    params.append('deviceid', deviceId);

    try {
      const response = await axios.post(SPA_SERVER_URL + 'api/token', params, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
      });
      if (response.data.response) {
        const responseJson = response?.data;
        const access_token = responseJson.sesssionid;
        const ecode = responseJson.ecode;
        const expiryDate = responseJson.expiredate;
        const authObj = {
          expiryDate: expiryDate,
          access_token: access_token,
          ecode: ecode,
        };
        setAuth(authObj);
        localStorage.setItem('data', JSON.stringify(authObj));
        // getSlots(access_token);
        getStaff(access_token);
        setLoading(false);
        navigate(from, { replace: true });
      } else {
        setErrMsg('Username or Password Incorrect!');
        setLoading(false);
      }
    } catch (err) {
      setErrMsg(err);
      setLoading(false);
    }
  };

  return (
    <Container fluid className='auth-bg'>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Row className='pt-5 justify-content-md-center'>
            <Col md={{ span: 4, offset: 0 }} className='logo-conatainer'>
              <Image src={logo} className='logo' rounded />
            </Col>
          </Row>
          <Row className='pt-5 justify-content-md-center'>
            <Col
              md={{ span: 5, offset: 0 }}
              className='signin-container mt-5 pt-5'
            >
              {errMsg ? (
                <Alert variant='warning' className='mx-5' ref={errRef}>
                  {errMsg}
                </Alert>
              ) : (
                ''
              )}
              <h4 className='mt-5 text-center'>Sign In</h4>
              <Form className='mx-5' onSubmit={handleSubmit}>
                <Form.Group className='mb-3' controlId='formBasicEmail'>
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Username'
                    autoComplete='off'
                    onChange={(e) => setUser(e.target.value)}
                    value={user}
                    ref={userRef}
                    required
                  />
                </Form.Group>

                <Form.Group className='mb-4' controlId='formBasicPassword'>
                  <Form.Label>Password</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type={showPassword ? 'text' : 'password'}
                      placeholder='Password'
                      onChange={(e) => setPwd(e.target.value)}
                      value={pwd}
                      required
                    />
                    <Button
                      variant='outline-secondary'
                      onClick={() => setShowPassword((prev) => !prev)}
                    >
                      {showPassword ? '👁' : '👁'}
                    </Button>
                  </InputGroup>
                </Form.Group>

                <div className='d-grid gap-2'>
                  <Button variant='dark' size='lg' type='submit'>
                    Submit
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
}

export default SignIn;
